import { mapState } from 'vuex'
import $ from 'jquery'

import 'jquery-match-height/dist/jquery.matchHeight-min.js'

export default {
  name: 'delivery',
  data () {
    return {
      msg: 'This is modules catalog',
      // activeShippingId: null,
      // currencies: null,
      deliveryMethodNumber: null,
      disableFields: false,
      sortedDeliveryMethods: {}
    }
  },
  props: [
    'deliveryMethods',
    'order',
    'currencies',
    'next_tick',
    'setShippingId',
    'onSubmitForm',
    'send_post',
    'activeShippingId'
  ],
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]}),
      exchange: (state) => state.currency.exchange,
      integrationHost: (state) => state.integrationHost
    })
  },
  methods: {
    changeShippingMethod: function (index) {
      // this.activeShippingId = index
      if (!this.disableFields) {
        // this.next_tick(3)
        this.order.shipping_cost = this.deliveryMethods[index]['total']
        this.deliveryMethodNumber = this.deliveryMethods[index]['delivery_method_number']
        this.setShippingId(this.deliveryMethodNumber)
      }
    },
    commitMethod: function () {
      this.$store.dispatch('updateShipping', this.deliveryMethodNumber)
      this.disableFields = true
      if (this.deliveryMethodNumber) {
        this.next_tick(4)
        this.setShippingId(this.deliveryMethodNumber)
        // this.onSubmitForm()
      } else if (this.activeShippingId) {
        this.next_tick(4)
        this.setShippingId(this.activeShippingId)
      } else {
        console.log(this.deliveryMethodNumber, this.activeShippingId)
        this.enablePrevStep()
      }
    },
    generateSsv4UserData: function () {
      return {
        ScreenResolution: window.screen.availWidth + 'x' + window.screen.availHeight,
        ScreenColorDepth: window.screen.colorDepth,
        JavaEnabled: navigator.javaEnabled ? (navigator.javaEnabled() ? 1 : 0) : 0,
        userEmstrack: {
          domain: window._ems_env_selectspecs || null,
          customer: window._ems_customer || null,
          visitor: window._ems_visitor || null,
          session: window._ems_session || null,
          campaign: window._ems_campaign || null
        },
        tagManagerId: window.tagManagerId || null
      }
    },
    redirectToThsnk: function () {
      this.$store.dispatch('updateShipping', this.deliveryMethodNumber)
      this.disableFields = true
      this.send_post('/checkout/checkout/change-payment-system', {
        order_hash: this.order.hash,
        payment_system: 'CheckoutPaypal',
        user_data: this.generateSsv4UserData()
      }, this.integrationHost)
        .then(() => {
          if (this.deliveryMethodNumber) {
            this.setShippingId(this.deliveryMethodNumber)
            // this.onSubmitForm()
          } else if (this.activeShippingId) {
            this.setShippingId(this.activeShippingId)
          } else {
            this.enablePrevStep()
          }
          document.location.href = '/checkout/thank/' + this.order.hash + (!(this.$route.query.sspay === undefined) ? '/?sspay=true' : '')
        })
    },
    enablePrevStep: function () {
      this.disableFields = false
    },
    decodeHtml: function (html) {
      var txt = document.createElement('textarea')
      txt.innerHTML = html
      return txt.value
    },
    sortByPrice: function () {
      this.sortedDeliveryMethods = Object.values(this.deliveryMethods).sort((method1, method2) => {
        return method1.total - method2.total
      })
      // for (let meth in sortedMeth) {
      //   this.sortedDeliveryMethods[sortedMeth[meth].id] = sortedMeth[meth]
      // }
    }
  },
  mounted: function () {
    $('.form-check').matchHeight({byRow: false})
    // if (this.deliveryMethods[0] || this.deliveryMethods[0]['price']) this.disableFields = true
    console.log(this.order.final_total, this.order.shipping_cost, this.order.final_total + this.order.shipping_cost)
  },
  created: function () {
    window.globalEvents.$on('enableFields', () => (this.enablePrevStep()))
    window.globalEvents.$on('deleteFields', () => (this.enablePrevStep()))
    window.globalEvents.$on('enableShipping', () => (this.enablePrevStep()))
    this.sortByPrice()
  },
  watch: {
    activeShippingId () {
      this.deliveryMethodNumber = this.activeShippingId
    },
    deliveryMethods () {
      this.sortByPrice()
    }
  }
}
